import React, { useState } from 'react';
import Carousel, { Dots, slidesToShowPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Image } from 'antd';
import './index.css';
import $ from 'jquery';
import { Pause } from '@material-ui/icons';

export const FishCarousel2 = () => {
/*   const handleSwipe = (el) => {
    alert("test");

  }; */
  return (
    <section>

  <article id="fishCarousel2" /* onClick={handleSwipe} */>
    <div>
      <ul>
      <li><Image preview={true} className={"imgStyle"} src={"img/CollabSnailana.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/RoboKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Base3.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Yin.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/CosmicKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Yang.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/TattooKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/PreviewKoi4.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/MaskedFish.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Sensei.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/ZombiekoiGrey.jpeg"}></Image></li>

      </ul>
    </div>
    <div>
      <ul>
      <li><Image preview={true} className={"imgStyle"} src={"img/CollabSnailana.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/RoboKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Base3.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Yin.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/CosmicKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Yang.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/TattooKoi.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/PreviewKoi4.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/MaskedFish.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/Sensei.jpg"}></Image></li>
        <li><Image preview={true} className={"imgStyle"} src={"img/ZombiekoiGrey.jpeg"}></Image></li>

      </ul>
    </div>
  </article>
</section>
  )
}

function setActive(arg0: boolean) {
  throw new Error('Function not implemented.');
}
