import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import './components/ChasingBordersButton/index.css'

import { Image } from "antd";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { fadeInUp } from 'react-animations';

import './bubbles.css';


import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import { Timeline } from './components/Timeline';
import { FishCarousel } from './components/FishCarousel';

import 'antd/dist/antd.css'

import CountUp from "react-countup";
import { Team } from "./components/Team";

import { Bubbles } from "./components/Bubbles";
import { FishCarousel2 } from "./components/FishCarousel2";
import { FAQ } from "./components/FAQ";
import { Statistics } from "./components/Statistics";
import { FishCarouselOG } from "./components/FishCarouselOG";



const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

const FadeInAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${FadeInAnimation};
`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();



  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! You got a Koi, welcome to the Pond!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  return (
    <main className="mainLayout">



      
        {/* <Bubbles/> */}
       
      {/*<div id="background-wrap">
        <div className="bubble x1"></div>
        <div className="bubble x2"></div>
        <div className="bubble x3"></div>
        <div className="bubble x4"></div>
        <div className="bubble x5"></div>
        <div className="bubble x6"></div>
        <div className="bubble x7"></div>
        <div className="bubble x8"></div>
        <div className="bubble x9"></div>
        <div className="bubble x10"></div>
  </div>*/}
      {/* 
      <Header></Header>
      {wallet && (
        <p>Address: {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
      )}

      {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}

      {wallet && <p>Total Available: {itemsAvailable}</p>}

      {wallet && <p>Redeemed: {itemsRedeemed}</p>}

      {wallet && <p>Remaining: {itemsRemaining}</p>}

      <MintContainer>
        {!wallet ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
        )}
        </MintContainer>

        <MintContainer>
          {!wallet ? (
            <ConnectButton>Connect Wallet</ConnectButton>
          ) : (
            <MintButton
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
              variant="contained"
            >
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress />
                ) : (
                  "MINT"
                )
              ) : (
                <Countdown
                  date={startDate}
                  onMount={({ completed }) => completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                />
              )}
            </MintButton>
          )}
        </MintContainer> 

        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>   */}





<FadeInDiv className="welcomeDiv noSelect">
<div className="schnabulatorDiv">
            {/* <LottieTurtle/> */}
            <Bubbles/>
            <img className="imageDecoration" src="img/schnabulator_flipped.png"></img>
          </div>
          <div className="lillyDiv">
            {/* <LottieTurtle/> */}
            <Bubbles/>
            <img className="imageDecoration" src="img/lilly_flipped.png"></img>
          </div>

        <div className="welcomeHeadings">
          
        
          {/*
      <Link  to={'/roadmap'} ><h1><a className="navLinks" >View Roadmap <FaArrowAltCircleRight style={{transform: "translateY(+20%)"}}/></a></h1> </Link>
    
      <hr style={{marginBottom: "50px"}}className="horizontal-divider"></hr>
      */}
          <br/>
          <h1 id="welcomeHeadline"><b>Koin Koi</b></h1> 
          <h2>  <span className="welcomeNumber">A Solana NFT project.</span></h2>
          <br/>
          {/* <h2 className="balsamiq">Public Mint in October:</h2> */}
    {/* 
           <h1>Public Mint: <span className="welcomeNumber">
               <Countdown
                  date={new Date(1633802400000)}
                  renderer={renderCounter}
                />
            </span></h1>  */}
       
          <h2>Stealth launch: <span className="welcomeNumber">
                 <Countdown
                  date={startDate}
                  onMount={({ completed }) => completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                /> 
               
            </span></h2>
          
         

          
          
          {/*<h1>Please join our <a className="social-links" href="https://discord.gg/Re4ADVBjV5">Discord</a> and follow us on <a className="social-links" href="https://twitter.com/KoinKoi_io">Twitter</a> for updates.</h1>*/}
        </div>
        

        
         <MintContainer>
       
          {!wallet ? (
            <WalletDialogButton id="bodyConnectButton">
            <span id ="notConnectedSpan" className="welcomeHeadings animated-button7">
             Not connected
             <div id="animatedSpans">
             <span></span>
              <span></span>
              <span></span>
              <span></span>
              </div>
             </span>
             </WalletDialogButton>
            /* <ConnectButton id="connectorButton" className="connectButton">
              <a href="#" id="connectorButtonA" className="animated-button8">
                Connect Wallet
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </a>
              
          </ConnectButton> */
          ) : (
            <MintButton
              className="mintButton"
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
              variant="contained"
              id="mintButton"
            >
          
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress id="mintLoadIndictaor" />
                ) : (
                  <span className="animated-button7">
                  Mint a Koi
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  </span>
                )
              ) : (
                <span className="animated-button7">
                  Minting period hasn't started yet.
                  </span>    
              )}

             
              
            </MintButton>
          )}
        </MintContainer>
        <br/>
      <h1>
      {wallet && <p>Price: <span className="welcomeNumber">0.25 SOL</span></p>}

      {wallet && (
        <p>Your Wallet: <span className="welcomeNumber">{shortenAddress(wallet.publicKey.toBase58() || "")}</span></p>
      )}

      {wallet && <p>Your Balance: <span className="welcomeNumber">{(balance || 0).toLocaleString()} SOL</span></p>}

        {  wallet && <p>Total minted: <span className="welcomeNumber">{itemsRedeemed+60+30} / {itemsAvailable+60+30}</span></p>
        }  

      
      </h1>


         

        <Snackbar
          open={alertState.open}
          anchorOrigin={{horizontal: "center", vertical: "top"}}
          className="SnackBar"
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            className="Alert"
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar> 

        </FadeInDiv> 


        
    <FadeInDiv className="welcomeDiv">
   
        <div className="welcomeHeadings">
          {/*
      <Link  to={'/roadmap'} ><h1><a className="navLinks" >View Roadmap <FaArrowAltCircleRight style={{transform: "translateY(+20%)"}}/></a></h1> </Link>
    
      <hr style={{marginBottom: "50px"}}className="horizontal-divider"></hr>
      */}
      
          <div>
            <h2>In some far away place... you found us!</h2>
            <br/>
            <h3>The first Solana NFT project that allows to create your own decentralized pond.</h3>
            <br/>
            <h3>The Koin Koi are part of our first and exclusive NFT collection.</h3>
            <br/>
            <h3>Hand-drawn Attributes: <span className="welcomeNumber"><CountUp delay={1.5} useEasing={true} end={200} duration={8}></CountUp>+</span></h3>
            <br/>
            <h3>Unique NFTs: <span className="welcomeNumber"><CountUp delay={1.5} useEasing={true} separator={","} end={1141} duration={8}></CountUp></span></h3>
            <br/>

            <h3>Mint Price: <span className="welcomeNumber">0.25 SOL</span></h3>
            <br/>

            <h3>Launch: <span className="welcomeNumber">Stealth</span></h3>
            <br/>
            
          </div>

          {/*<h1>Please join our <a className="social-links" href="https://discord.gg/Re4ADVBjV5">Discord</a> and follow us on <a className="social-links" href="https://twitter.com/KoinKoi_io">Twitter</a> for updates.</h1>*/}
        </div>

       
      


        {/*<div className="constructionHeadings">
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/Base1.jpg"}></Image>}</div>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/Base2.jpg"}></Image>}</div>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/Base3.jpg"}></Image>}</div>
          <p></p>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/MaskedFish.jpg"}></Image>}
          <h1>New!</h1>
          </div>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/TattooKoi.jpg"}></Image>}
          <h1>New!</h1>
          </div>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/PreviewKoi4.jpg"}></Image>}
          <h1>New!</h1>
          </div>
          <p></p>
          <div className={"cardDivStyle"}>{<Image style={imgStyle} src={"img/CollabSnailana.jpg"}></Image>}
          <h1>Snailana Collab!</h1>
          </div>
  </div>*/}



        {/*}
        <Coverflow width="1000%" height="600" classes={{background: 'rgb(233, 23, 23)'}} className=''
          displayQuantityOfSide={2}
          navigation={true}
          enableScroll={true}
          clickable={true}
          active={3}
          enableHeading={false}
          currentFigureScale={1.0}
          otherFigureScale={0.6}
          
        >

      <img width="3500" src='img/Base1.jpg' alt='Base 1' data-action="/img/Base1.jpg"/>
      <img width="3500" src='img/Base2.jpg' alt='Base 2' data-action="/img/Base2.jpg"/>
      <img width="3500" src='img/Base3.jpg' alt='Base 3' data-action="/img/Base3.jpg"/>
      <img width="3500" src='img/MaskedFish.jpg' alt='Masked Fish' data-action="/img/MaskedFish.jpg"/>
      <img width="3500" src='img/TattooKoi.jpg' alt='Tattoo Koi' data-action="/img/TattooKoi.jpg"/>
      <img width="3500" src='img/PreviewKoi4.jpg' alt='Preview Koi 4' data-action="/img/PreviewKoi4.jpg"/>
      <img width="3500" src='img/CollabSnailana.jpg' alt='Collab Snailana' data-action="/img/CollabSnailana.jpg"/>
    </Coverflow> 
*/}
    
     {/*  <FishCarousel/> */}
     <div className="constructionHeadings">
     <h1 style={{marginRight: "50%", transform: "rotate(-2deg)"}}>Koi Collection</h1>
      <FishCarousel2/>
      
        <h1 style={{marginLeft: "50%", transform: "rotate(-2deg)"}}>1/1 OG Collection</h1>
      
      <FishCarouselOG/>
      </div>
      </FadeInDiv>

        {/* <hr style={{ marginBottom: "50px" }} className="horizontal-divider"></hr> */}
        
        
        <FadeInDiv className="pondmapDiv">
          <div id="pondmapHeader" className="constructionHeadings">
            <h1>Pondmap 1.0</h1>
            <hr style={{ marginBottom: "50px" }} className="horizontal-divider"></hr>
          </div>
          

          <Timeline></Timeline>
          <p className="timelineArrow">▼</p>
          <div  className="constructionHeadings">
            <h1>In-fin-ity</h1>
          </div>
          <br/>
          <br/>
        </FadeInDiv>

        <FadeInDiv className="faqDiv">
        <div id="faqHeader" className="constructionHeadings">
      {/*   <hr className="horizontal-divider"></hr> */}
            <h1>FAQ's</h1>
            
        </div>
          <FAQ/>
          <br/>
          <br/>
        </FadeInDiv>

        

        <FadeInDiv className="teamDiv">
        <div id="teamHeader" className="constructionHeadings">

            <h1>Core Team</h1>
            
        </div>
          
        <Team></Team>
        
        </FadeInDiv>
        

      

      

      </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
