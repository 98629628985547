export const resolveSequentially = function (items: any[], func, setCounter?) {
    return items.reduce((previousPromise, item, i) => {
      return previousPromise.then(() => {
        if (setCounter) {
          setCounter(i + 1)
        }
        return func(item);
      }).catch(e => console.log(e));
    }, Promise.resolve());
  };

  //Source: https://github.com/penta-fun/sol-nft-tools/blob/4a7736980a4275464299712b90989b33cecf08b9/util/resolve-sequentially.ts