import React, { useState } from 'react';

import { Image } from 'antd';

export const Team = () => {

  return (
    <div className="teamWrapper">
      <div className="teamEntry">

        <img className="teamEntryImage" src="img/schnabulator.png" loading="lazy">
       
        </img>
     
        <h1>Schnabulator</h1>
        <p className="textParagraph" ><i>IT-Security Researcher</i><br/>Working with crypto for 3+ years.</p>
      </div>
      <div className="teamEntry">

        <img className="teamEntryImage" src="img/lilly.png" loading="lazy"></img>
        <h1>Lilly</h1>
        <p className="textParagraph" ><i>Artist</i><br></br>Devoted to animals, writing and drawing.</p>
      </div>



      <br/>
      

      <div className="teamEntry">
        <img className="teamEntryImage" src="img/pixzel.png" loading="lazy"></img>
        <h1>PixZel</h1>
        <p className="textParagraph" ><i>Koimmunity Manager</i><br/>Memes are love, memes are life.</p>
      </div>


      <div className="teamEntry">
        <img className="teamEntryImage" src="img/yoshi.png" loading="lazy"></img>
        <h1>Yoshi</h1>
        <p className="textParagraph" ><i>Koimmunity Manager</i><br/>Brain incorrectly wired, i am insane.</p>
      </div>

      <div className="teamEntry">
        <img className="teamEntryImage" src="img/mathoul.png" loading="lazy"></img>
        <h1>Mathoul</h1>
        <p className="textParagraph" ><i>Koimmunity Manager</i><br/>Been there. Done that.</p>
      </div>

      <div className="teamEntry">
        <img className="teamEntryImage" src="img/ghost.png" loading="lazy"></img>
        <h1>Ghost</h1>
        <p className="textParagraph" ><i>Attorney at Law</i><br/>Paragraph Connoisseur</p>
      </div>

      <div className="teamEntry">
        <img className="teamEntryImage" src="img/pandalf.png" loading="lazy"></img>
        <h1>Pandalf</h1>
        <p className="textParagraph" ><i>Part-time project assistant (Web-Dev)</i><br/>Loves CSS. Hates CSS.</p>
      </div>
    </div>
  )
}