import { SocialIcon } from "react-social-icons";


export const Footer = () => {

  
  return (

    
    <div className="footer">
          <SocialIcon url="https://twitter.com/KoinKoi_io" label="Koin Koi Twitter" style={{ width: 60, height: 60, margin: 25 }}></SocialIcon>
          <SocialIcon url="https://discord.gg/Re4ADVBjV5" label="Koin Koi Discord" style={{ width: 60, height: 60, margin: 25 }}></SocialIcon>
          <SocialIcon url="https://medium.com/@mail_51887/the-koi-story-a-solana-nft-project-76c689bd166f" label="Medium Story" style={{ width: 60, height: 60, margin: 25}}></SocialIcon>
        <p>&copy; Koin Koi {new Date().getFullYear()}</p>
    </div>
    
  );


  
};

