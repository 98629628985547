import Collapsible from 'react-collapsible';

export const FAQ = () => {

  return (
    <div id="faqContainer">
    

    <Collapsible className="noSelect" trigger="What is Koin Koi?">
    <div className="constructionHeadings">
      <p className="textParagraph">
      Koin Koi entitles an exclusive hand-drawn NFT collection of 1,141 one-of-a-kind Koi floating on the Solana blockchain. There are over 200+ attributes. 30 kois are fully customized art pieces for our most loyal OGs while 1,111  algorithmically generated.
      <br/>
      Check out our <a target="_blank" href="https://medium.com/@mail_51887/the-koi-story-a-solana-nft-project-76c689bd166f">Medium</a> Story.    
    </p>
    </div>
    </Collapsible>

    <Collapsible className="noSelect" trigger="What are you trying to build?">
    <div className="constructionHeadings">
      <p className="textParagraph">
      A living world! 
      <br/>
      Imagine an animated pond you can easily share to your friends that shows your NFTs happily paddling within. Music, greenery and other collections allow for customization and to make your pond unique.
      <br/>In addition, we are planning to create a visual novel at some point that allows you to use your Koi NFT as the MC!
      
      
    </p>
    </div>
    </Collapsible>


    <Collapsible className="noSelect" trigger="Are there rarities in the Koin Koi collection?">

    <div className="constructionHeadings">
      <p className="textParagraph">
      All Koi are splendid. However, they may differ in amount and rarity of traits. Sailors also mumble about legendary Koi and - when enough rum was poured - they whisper about an unique cosmic Koi...
    </p>
    </div>
    </Collapsible>

    <Collapsible className="noSelect" trigger="Will there be an after-market?">
    <div className="constructionHeadings">
      <p className="textParagraph">
      Yes, Magic Eden + Digital Eyes will be the first ones.
    </p>
    </div>
    </Collapsible>

    <Collapsible className="noSelect" trigger="What is the mint price?">
    <div className="constructionHeadings">
      <p className="textParagraph">
      0.25 SOL.
    </p>
    </div>
    </Collapsible>

    <Collapsible className="noSelect" trigger="Are there royalties?">
    <div className="constructionHeadings">
      <p className="textParagraph">
      Yes, we collect 7.5% to ensure future development and rewards. These go to the team directly. 
      <br/>
      Our NFTs are no financial instrument.
    </p>
    </div>
    </Collapsible>
    </div>
  )
}