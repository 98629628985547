import { useEffect, useRef, useState } from 'react';
import './index.css';
import $, { inArray } from "jquery";
import "jquery.waitforimages";
import { getParsedProgramAccounts } from '../Web_Requests';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { publicKey } from '@project-serum/anchor/dist/cjs/utils';
import { Connection } from '@solana/web3.js';
import { getMetadata } from '../Web_Requests/get-meta';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import { CircleLoader } from 'react-spinners';
import { LoadingSpinner } from '../LoadingSpinner';
import { stringify } from 'querystring';
import { JsonToTable } from 'react-json-to-table';
import {  useParams, useLocation} from "react-router-dom";
import { type } from 'os';
import { SingleBedSharp } from '@material-ui/icons';
import { idMintMap, koiMints } from '../../mintData';
import useWebAnimations from "@wellyshen/use-web-animations";


//Some of the sources:
//https://spl.solana.com/token#finding-all-token-accounts-for-a-specific-mint

export interface PondProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
  //mintAnchor: anchor.web3.PublicKey; //MintAnchor describes the mintHash that is used to detect the corresponding wallet
}


export const Pond = (props: PondProps) => {
    //POND PROPERTIES
 
    const moveSpeed = useRef<number>(10);
    const [moveSpeedState, setMoveSpeedState] = useState<number>(moveSpeed.current);
    const [fishSize, setFishSize] = useState<number>(10);
    //END POND PROPERTIES


  const pondId = useParams()["id"];

  const endpoint = "https://explorer-api.mainnet-beta.solana.com";
  const candy_machine_key_main = new anchor.web3.PublicKey("8tDuRKyTaELtisZn4ef38gDaCVbuUeJiezXbYJWTB6DL");
  const candy_machine_key_presale = new anchor.web3.PublicKey("7RQthNXget1Dm9cQyjx81YVrBqav1Lvi6qpv4gurdzME");
  const valid_mint_hash = new anchor.web3.PublicKey("F3JCcAucZcpZbLKzCWnRhRb1KKNez9GBt2FN9jW8YytC");
  const candy_machine_program_id = new anchor.web3.PublicKey("cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ");
  const programAccountKey = new anchor.web3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
  const wallet = useAnchorWallet();


  const defaultLoadingMessage = "Rendering your pond...";
  const defaultErrorMessage = "Whoops, something went wrong! :-(";
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>(defaultLoadingMessage);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);
  const [is404, setIs404] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [parsedProgramAccounts, setParsedProgramAccounts] = useState<any>();
  const [anchorWallet, setAnchorWallet] = useState<anchor.web3.PublicKey>();
  const [nfts, setNfts] = useState<any[]>([]);
  const [mintAnchor, setMintAnchor] = useState<anchor.web3.PublicKey | string>();

  const staticWallet = new anchor.web3.PublicKey("wRpX9TXgrvZ8rD6VQQzZV5Qxi8NrQiZTWBF7ZCuMvtz");

  useEffect(() => {
    try{
      const mint = new anchor.web3.PublicKey(idMintMap[pondId]);
      setMintAnchor(mint);
      console.log("Associated mint to this page: " + mint.toBase58());
    }
    catch(error){
      console.log("Could not detect associated mint to this page.");
      setIs404(true);
    }
  }, pondId);

  useEffect(() => {

    const fetchMintsWithMetadata = async () => {
      setIsError(false);
      setLoadingMessage("Find anchor wallet...");
      setIsLoading(true);
      if(!(mintAnchor instanceof anchor.web3.PublicKey)){
        setIsError(true);
        setIsLoading(false);
        console.log("Could not parse mintAnchor to PublicKey");
        return;
      }
      //Get wallet by mintAnchor
      if(!anchorWallet){
        console.log("MintAnchor:");
        console.log(mintAnchor.toBase58());
        try {
          console.log("Determining the anchor wallet of the anchor mint '"+mintAnchor+"' ...");
          const anchorWalletResponse = await props.connection.getParsedProgramAccounts(programAccountKey,
            //const result = await props.connection.getParsedProgramAccounts(programAccountKey,
            {
              filters:
                [{ dataSize: 165 },
                { memcmp: { offset: 0, bytes: mintAnchor.toBase58() } }]
            });
          console.log("ParsedProgramAccounts Response:");
          console.log(anchorWalletResponse);
          for (var i = 0; i < anchorWalletResponse.length; i++) { 
            if (anchorWalletResponse[i].account.data["parsed"]["info"]["tokenAmount"]["amount"] == "1") {
              await setAnchorWallet(new anchor.web3.PublicKey(anchorWalletResponse[i].account.data["parsed"]["info"]["owner"]));
              console.log("Detected an anchor wallet.");
              break;
            }
          }
          /*console.log(anchorWallet);
          if (!anchorWallet) {
            throw new Error("Response for 'getParsedProgramAccounts' for the given mintAnchor was received and parsed without errors, but a wallet with supply=1 of this mintAnchor could not be found.");
          }*/
        }
        catch (error) {
          console.log("Could not determine the anchor wallet of the pond for the mintAnchor: '" + mintAnchor + "'.");
          console.log(error);
          setIsError(true);
          setErrorMessage("Could not determine the anchor wallet for the mint associated to this pond.");
          setIsLoading(false);
          return 0;
        }
      }
      else{
        setIsLoading(false);
      }
    }
      fetchMintsWithMetadata();
    }, [props.connection, mintAnchor]);

    useEffect(() => {

      const fetchMintsWithMetadataPart2 = async () => {
        if(anchorWallet){

      //Get all Token Accounts of this wallet
      try {
        setLoadingMessage("Fetching token accounts...");
        console.log("Requesting all token accounts for this anchor wallet: " + anchorWallet.toBase58());
        const tokenAccounts = await props.connection.getParsedProgramAccounts(programAccountKey,
          //const result = await props.connection.getParsedProgramAccounts(programAccountKey,
          {
            filters:
              [{ dataSize: 165 },
              { memcmp: { offset: 32, bytes: anchorWallet.toBase58() } }]
          });
        setParsedProgramAccounts(tokenAccounts); //Save them TODO: Waste of Memory?
        //console.log(result);


        //Now: Iterate through all Accounts, check if supply is ===1 (so if the Account actually owns the mint right now)
        const nftArray: any[] = [];
        console.log("Filtering for TokenAccounts holding a Koi NFT...");
        setLoadingMessage("Fishing for koi in your pond...");
        
        for (var i = 0; i < tokenAccounts.length; i++) {
          
          if (tokenAccounts[i].account.data["parsed"]["info"]["tokenAmount"]["amount"] == "1") {
            //nftArray.push(result[i].account.data["parsed"]["info"]["mint"]);

            //As we found a valid Token Account, try to read the Metadata of the token itself aswell as the Arweave Metadata. (Should also work with IPFS)
            try {
              if(!(tokenAccounts[i].account.data["parsed"]["info"]["mint"] in koiMints)){  //Skip everything that is not a koi.
                //continue;
              }

              setLoadingMessage("Detected koi: " + (nftArray.length + 1) );
              const mintKey = await new anchor.web3.PublicKey(tokenAccounts[i].account.data["parsed"]["info"]["mint"]);
                
              const tokenMetadata = await getMetadata(mintKey, endpoint);

              const arweaveData = await fetch(tokenMetadata.data.uri).then((res) =>
                res.json().catch() //Parse the received Json
              ).catch((error) => {
                console.log("Error fetching Arweave Data."); //Oh neim
                // mints.push({tokenMetadata, failed: true})
              });
              //Push received data nicely into the nftArray (resultArray)
              nftArray.push({
                tokenData: {
                  ...tokenMetadata.data,
                  creators: tokenMetadata.data.creators.map((d) => {
                    return {
                      share: d.share,
                      address: new anchor.web3.PublicKey(d.address).toBase58(),
                      verified: !!d.verified,
                    };
                  }),
                },
                metadata: arweaveData,
                mint: mintKey,
              });
              
              //const metadata = await props.connection.getParsedAccountInfo(mintKey);
              //const metadata = await getFetchFullMetadataFunction(endpoint, result[i].account.data["parsed"]["info"]["mint"]);

              //console.log("Current Mint: " + result[i].account.data["parsed"]["info"]["mint"])
              //nftArray.push(metadata);
            }
            catch (error) {
              console.log("Error for this Token Account: ");
              console.log(tokenAccounts[i]);
              setLoadingMessage(String(error));

            }
          }
          //console.log("Mint: " + result[i].account.data["parsed"]["info"]["mint"])
          //console.log("Supply: " + result[i].account.data["parsed"]["info"]["tokenAmount"]["amount"]);
        }
        setNfts(nftArray); //Save the NFTs ser
        console.log("Valid NFTs:");
        console.log(nftArray);
      } catch (error) {
        console.log("Error during fetching your token accounts and mint metadata.");
        console.log(error);
        setIsError(true);
        setErrorMessage("Critical error when trying to find associated koi :-(")
      }
      
      setIsLoading(false);
      setLoadingMessage(defaultLoadingMessage);
      
    };
  }
    fetchMintsWithMetadataPart2();
    
  }, [props.connection, anchorWallet]);

  //const [fishImageLinks, setFishImageLinks] = useState<any>(null);
  const [pondSpawned, setPondSpawned] = useState<any>(false);
  useEffect(() => {
    const constructPond = () => {
    
      if (pondSpawned == true || nfts.length < 1) {
        return;
      }


      var count, pond, header, pondWidth, pondHeight, fishSize;
      count = $(".count");
      pond = $(".pond");
      pond.empty(); //clear old pond first
      header = $(".headerWavesWrapper");
      determinePondSize();
      determineInitialFishSize();

      $(window).on("resize", determinePondSize);

      //$pond.on("click":0, stirPond);

      // fill the pond
      spawnStartingFish();
      setPondSpawned(true);

      function spawnStartingFish() {
        for (var i = 0; i < nfts.length; i++) {
          let imageUrl = nfts[i]["metadata"]["image"];
          let mintHash = nfts[i]["mint"].toBase58();
          spawnFish(getRandom(fishSize,pondWidth-fishSize), getRandom(fishSize,pondHeight-fishSize), imageUrl, mintHash);

        }
      }




    /*function stirPond(event) {
      spawnFish(event.clientX, event.clientY);
    }*/

    function spawnFish(x, y, imageUrl, mintHash) {
      // setup fish
      var fish = $(
        '<div class="fish"><div class="fish-bob"><div class="fish-direction"><div class="fish-body"></div></div></div></div>'
      );
      fish.data("mintHash", mintHash);
      /*console.log(standardMoveSpeed);
      fish.css({
        "transition": "transform " + {standardMoveSpeed} + "s"
      });*/

      //var colors = [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 4];
      //fish.addClass("fish-" + colors[Math.floor(getRandom(15))]);
      if (getRandom(0,2) < 1) {
        fish.addClass("fish-flip");
      }
      fish.find(".fish-bob").css("animation-delay", "-" + getRandom(0,7) + "s");
      fish.find(".fish-body").on("click", pokeFish.bind(true, fish));
      fish.find(".fish-body").on("dblclick", showFish.bind(true, fish, mintHash));
      positionFish(fish, x, y);

      fish.find(".fish-body").css({
        /*"background-image": "url('img/CollabSnailana.jpg')":0,*/
        "background-image": "url(" + imageUrl + ")",
        "background-size": "cover",
        "width": fishSize,
        "height": fishSize
      });


      //Wait for Image to be loaded
      var img = new Image();
      img.onload = function(){
        console.log("Spawned fish with url: " + imageUrl + ", mint was: " + mintHash);
        // let fish go when image was loaded
        pond.append(fish);
      }
      img.src = imageUrl;
      

      setTimeout(doFishyThings.bind(true, fish), getRandom(0, 10000));
      count.text($(".fish").length);
    }

    function pokeFish(fish) {
      fish.toggleClass("fish-spin");
      return false;
    }

    function showFish(fish, mintHash) {
      window.open("https://solscan.io/token/" + mintHash, "_blank")?.focus();
    }

    function doFishyThings(fish) {
      blowBubble(fish);
      moveFish(fish);

      var timeout = fish.data("timeout");
      clearTimeout(timeout);
      timeout = setTimeout(
        doFishyThings.bind(true, fish),
        10000 + getRandom(0, 6000) //When will fish move again?
      );
      fish.data("timeout", timeout);
    }

    function blowBubble(fish) {
      var bubble = $('<div class="bubble-pond">');
      if (fish.hasClass("fish-flip")) {
        bubble.addClass("bubble-flip");
      }

      var x = fish.data("x");
      var y = fish.data("y");
      bubble.css({ top: y + "px", left: x + "px" });

      pond.prepend(bubble);
      setTimeout(popBubble.bind(true, bubble), 4000);
    }

    function moveFish(fish) {
      var x = getRandom(fishSize, pondWidth - fishSize);
      var y = getRandom(fishSize, pondHeight - fishSize);

      if (x < fish.data("x")) {
        fish.addClass("fish-flip");
      } else {
        fish.removeClass("fish-flip");
      }

      positionFish(fish, x, y);
    }

    function determinePondSize() {
      pondWidth = pond.width();
      pondHeight = pond.height();

    }
    function determineInitialFishSize() {
      if(pondWidth < 700){
        fishSize = 80;
      }
      else if(pondWidth < 1300){
        fishSize = 90;
      }
      else{
        fishSize = 100;
      }
    }

    function positionFish(fish, x, y) {
      
      console.log("setting speed: "  + moveSpeed.current);
      let newSpeed = moveSpeed.current;
      fish
        .css("transform", "translate(" + x + "px, " + y + "px)")
        .css("transition",   newSpeed + "s")
        .data("x", x)
        .data("y", y);
        
    }

    

    function popBubble(bubble) {
      bubble.remove();
    }

    function getRandom(lower, upper) {
      return Math.floor(Math.random() * (upper - lower) + lower);
    }
  }
  constructPond(); // Initiate pond here
  }, [pondSpawned, moveSpeed, wallet, props.connection, nfts]);

  function refreshPond(e){
    alert("test");
    setPondSpawned(false);
  }

  function changeMoveSpeed(e){
    moveSpeed.current = (parseFloat(e.target.value));
    setMoveSpeedState(moveSpeed.current);
  }

  return (

    <div>
      
      {/* <div id="controlsMenu">
      <input 
        type="btn" 
        id="refresh"
        onClick={refreshPond}
      />
        <input 
        id="typeinp" 
        type="range" 
        min="1" max="10" 
        value={moveSpeedState} 
        onChange={e => changeMoveSpeed(e)}
        step="1"
        >
      </input> 
      </div> */}
      <div className="pond"></div>     

      
      {isLoading ? (
      <div className="overlayDiv constructionHeadings">
      <LoadingOverlay
        id="loadingOverlay"
        active={isLoading}
      >
      <div className="loaderWrapper">
        <LoadingSpinner color1="#11d43b" color2="#11d43b"/>
        <h1>{loadingMessage}</h1>
      </div>
      </LoadingOverlay>     
      </div>
      ) : (null)}

{isError ? (
      <div className="constructionHeadings">

      <div className="errorWrapper constructionHeadings">
        <h1>{errorMessage}</h1>
      </div>
      </div>
      ) : (null)}
    </div>
  );
};



