import Lottie from "react-lottie"
import animationData from "./lotties/sun.json";

export const LottieSun = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  return (
      <Lottie 
	    options={defaultOptions}

        height={"100%"}
        width={"100%"}
        
      />
  );
};

