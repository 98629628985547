import { SocialIcon } from "react-social-icons";
import {
  HashLink as Link
} from "react-router-hash-link";

import { OceanWaves } from "../OceanWaves";

import { LottieSun } from "../LottieSun";

import '../LiquidFillButton/index.scss'
//import { DrippingLiquid } from "../DrippingLiquid";
import '../DrippingLiquid/index.css';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { shortenAddress } from "../../candy-machine";

import { LottieBirds } from "../LottieBirds";
import $ from 'jquery';
import { BurgerMenu } from "../BurgerMenu";

export const Header = () => {
  const wallet = useAnchorWallet();
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    var header = $(".header").outerHeight() || 0;
    const yOffset = 0;//After Browser Router, not necessary anymore.    -header-50; 
    console.log(yOffset);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
  return (
    <div className="header">
      
        <div className="sunWrapper">
          <LottieSun/>
        </div>
        
        <div className="birdsWrapper">
          <LottieBirds/>
          
        </div>
          
      

            <div id="navDiv">
            
           
              <span id="navLinks">
                
               <Link className="navLink hover-underline-animation" to="/#">Home</Link>   
                <span className="dotSep">·</span>
              <Link className="navLink hover-underline-animation" scroll={scrollWithOffset} to="/#pondmapHeader">Roadmap</Link>
              <span className="dotSep">·</span>
              <Link className="navLink hover-underline-animation" scroll={scrollWithOffset} to="/#faqHeader">FAQ</Link>
              <span className="dotSep">·</span>
              <Link className="navLink hover-underline-animation" scroll={scrollWithOffset} to="/#teamHeader">Team</Link>
              <span className="dotSep">·</span>
              <Link className="navLink hover-underline-animation" scroll={scrollWithOffset} to="/ponds">Ponds</Link>
              
              </span>
              
              <span id="socialNav">
                <SocialIcon target="_blank" url="https://twitter.com/KoinKoi_io" label="Koin Koi Twitter" style={{ width: 40, height: 40, margin: 10}}></SocialIcon>
                <SocialIcon target="_blank" url="https://discord.gg/Re4ADVBjV5" label="Koin Koi Discord" style={{ width: 40, height: 40, margin: 10}}></SocialIcon>
                <SocialIcon target="_blank" url="https://medium.com/@mail_51887/the-koi-story-a-solana-nft-project-76c689bd166f" label="Medium Story" style={{ width: 40, height: 40, margin: 10}}></SocialIcon>
              </span>
            
              {!wallet ? (<WalletDialogButton id="connectorButtonNav" >
              Not connected
            <div className="drops">
                <div className="drop1"></div>
                <div className="drop2"></div>  
           
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="liquid">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="liquid" />
                      </filter>
                    </defs>
                    </svg>
                    </div>
                  </WalletDialogButton>
              )
            : <span id="connectorButtonConnected">Connected: {shortenAddress(wallet.publicKey.toBase58() || "")}
            
            <div className="drops">
                <div className="drop1"></div>
                <div className="drop2"></div>  
           
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="liquid">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="liquid" />
                      </filter>
                    </defs>
                    </svg>
                    </div>
            </span>
            }

{/* 
            

                <div className="drops">
                <div className="drop1"></div>
                <div className="drop2"></div>  
           
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="liquid">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="liquid" />
                      </filter>
                    </defs>
                    </svg>

                    </div>  */}
            
               </div> 
          
        

      <div style={{clear: "both"}}></div>
      
      <OceanWaves/>
      </div>
    
  );


  
};

