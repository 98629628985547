import React, { useState } from 'react';
import Carousel, { Dots, slidesToShowPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Image } from 'antd';


export const FishCarouselOG = () => {
/*   const handleSwipe = (el) => {
    alert("test");

  }; */
  return (
    <section>

  <article id="fishCarousel2" /* onClick={handleSwipe} */>
    <div>
      <ul>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/0.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/1.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/2.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/3.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/4.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/5.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/6.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/7.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/8.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/9.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/10.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/11.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/12.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/13.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/14.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/15.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/16.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/17.jpg"}></Image></li>
      </ul>
    </div>
    <div>
      <ul>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/0.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/1.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/2.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/3.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/4.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/5.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/6.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/7.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/8.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/9.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/10.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/11.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/12.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/13.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/14.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/15.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/16.jpg"}></Image></li>
      <li><Image preview={true} className={"imgStyle"} src={"img/OGs/17.jpg"}></Image></li>

      </ul>
    </div>
  </article>
</section>
  )
}

function setActive(arg0: boolean) {
  throw new Error('Function not implemented.');
}
